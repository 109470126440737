/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
                var viewportWidth = $(window).width();

                // Fastclick
                FastClick.attach(document.body);

                // Remove empty p tags
                $('p:empty').remove();

                // External links
                $('a').filter(function(){
                    return this.hostname && this.hostname !== location.hostname;
                }).addClass('external').attr('target', '_blank');

                //*Mobile Menu*//
                $( ".c-header__toggle" ).click(function(e) {
                    e.preventDefault();

                    $(".c-header__mobile").slideToggle();
                });

                // for the mobile animation

                $(".c-header__toggle").click(function(){
                    $(this).toggleClass("is-active");
                });


                //
                //Home portal aircraft management background image
                //
                var colDesktop = $('.c-about__background').data('desktop'),
                colMobile = $('.c-about__background').data('mobile');
                if(viewportWidth < 1024 ){
                    $('.c-about__background').css('background-image', 'url('+colMobile+')');
                }else{
                    $('.c-about__background').css('background-image', 'url('+colDesktop+')');
                }

                //
                //Home portal aircraft management background image
                //
                var colDesktop2 = $('.c-testimonial__background').data('desktop'),
                colMobile2 = $('.c-testimonial__background').data('mobile');
                if(viewportWidth < 1024 ){
                    $('.c-testimonial__background').css('background-image', 'url('+colMobile2+')');
                }else{
                    $('.c-testimonial__background').css('background-image', 'url('+colDesktop2+')');
                }

                // Lazy load images
                $('.js-unveil').unveil(200);

                // Carousel(s)
                $('.js-hero-carousel-home').slick({
                    autoplay: true,
                    autoplaySpeed: 5000,
                    arrows: false,
                    dots: true,
                    pauseOnHover: true,
                    slidesToShow: 1,
                    slidesToScroll: 1
                });

                if (viewportWidth < 1024) {
                    $('.js-posts-carousel, .js-events-carousel').slick({
                        autoplay: true,
                        autoplaySpeed: 4000,
                        arrows: false,
                        dots: true,
                        pauseOnHover: true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    });
                }

                  if (!Cookies.get('landing') && document.querySelector('.js-popup')) {
                    setTimeout(function () {
                      $.fancybox.open({
                        src: '.popup-overlay',
                        type: 'inline'
                      });

                    Cookies.set('landing', 'true', { expires: 1 });
                  }, 1000);
                }

                // Site Scrolling
                $(window).scroll(function() {
                    var scrollY = $(this).scrollTop();

                    if (scrollY > 300) {
                        $('.js-scroll-up').addClass('is-active');
                    } else {
                        $('.js-scroll-up').removeClass('is-active');
                    }
                });

            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
